import 'utils/arrayFrom'

import React, { useState, useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import FirebaseContext from 'components/FirebaseProvider'
import FirebaseLogin from 'components/FirebaseProvider/Login'
import Link from 'components/Link'
import Spinner from 'components/Spinner'
import StoryblokComponents from 'components/StoryblokComponents'
import useFavicon from '../../hooks/useFavicon'

// eslint-disable-next-line react/prefer-stateless-function
function StoryblockDefaultPageTemplate({
  data: { page, navigation, footer },
  pageContext: { navMap },
  location,
}) {
  const [allComponentsLoaded, setAllComponentsLoaded] = useState(false)

  const { user } = useContext(FirebaseContext) || {}

  const gtmId =
    page?.component?.gtmId || navigation?.component?.gtmId || 'GTM-WQFR4LM' // default normstahl.com GTM ID
  const matomoId =
    page?.component?.matomoId || navigation?.component?.gtmId || '499' // default normstahl.com Matomo ID

  const favicon = useFavicon()

  return (
    <>
      <Helmet>
        <title>{page.component.title}</title>
        {Object.keys(navMap).reduce((acc, key) => {
          const match = key.match(/^_self_(.*)/)
          if (match) {
            return [
              ...acc,
              <link
                rel="alternate"
                hrefLang={match[1] === 'default' ? 'x-default' : match[1]}
                href={navMap[key]?.slug}
              />,
            ]
          }
          return acc
        }, [])}
        {page.component.canonicalUrl && (
          <link rel="canonical" href={page.component.canonicalUrl} />
        )}
        <meta name="description" content={page.component.description} />
        <meta name="keywords" content={page.component.keywords} />
        <meta name="title" content={page.component.title} />
        <meta name="robots" content={page.component.robotsMeta} />
        <meta
          name="p:domain_verify"
          content="4b5578cf701cbc5814f96e0ed22a5fa7"
        />
        <meta
          name="facebook-domain-verification"
          content="2xi3wrrfgt3rcybiui7w79aoxsg2g5"
        />
        <meta
          name="lang"
          content={
            page.lang === 'default' ? 'en' : page.lang.split('_').slice(-1)[0]
          }
        />
        <html
          lang={
            page.lang === 'default' ? 'en' : page.lang.split('_').slice(-1)[0]
          }
        />
        {/* Matomo tracking script */}
        <script>
          {`var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
var u=https://service.matomo.aws.assaabloy.com/;
_paq.push(['setTrackerUrl', u+'matomo.php']);
_paq.push(['setSiteId', '${matomoId}']);
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();`}
        </script>
        {/* GTM tracking script */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`}
        </script>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe
            src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          /></Helmet>`,
          }}
        />
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      {page.component.requireLogin && !user ? (
        <>
          <FirebaseLogin />
          {page.component.body[0].component === 'carousel' && (
            <StoryblokComponents components={[page.component.body[0]]} />
          )}
        </>
      ) : (
        <>
          <StoryblokComponents
            onLoaded={() => setAllComponentsLoaded(true)}
            components={page.component.body}
            root
            inheritBg="white"
          />
          {!allComponentsLoaded && <Spinner isFullscreen />}
        </>
      )}
    </>
  )
}

export default StoryblockDefaultPageTemplate

export const pageQuery = graphql`
  query($id: String!, $lang: String!) {
    footer: storyblokFooterStory(lang: { eq: $lang }) {
      ...storyblokFooterStory
    }
    navigation: storyblokNavigationStory(lang: { eq: $lang }) {
      ...storyblokNavigationStory
    }
    page: storyblokPageStory(id: { eq: $id }) {
      ...storyblokPageStory
    }
  }
`
